@import './adresse.scss';

.logement-page {
  margin: 0px 18px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .CircularProgressbar-path {
    stroke: red !important;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    h1 {
      font-size: 22px;
      font-weight: 600;
    }
  }

  .ant-input-outlined:focus-within {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0;
  }

  .search-input:hover {
    border-color: var(--primary-color) !important;
  }

  .empty-blc {
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 60px;
    display: flex;

    .blc {
      text-align: left;
      width: 350px;
    }

    h4 {
      font-size: 20px;
      color: black;
      margin: inherit;
    }

    p {
      margin: 0px;
      margin-bottom: 9px;
    }

    .en-savoir-plus {
      margin-bottom: 20px;
      display: block;
    }

    .home-icon {
      margin-bottom: 6px;
      background: #f5f5f5;
      display: inline-block;
      border-radius: 7px;
      padding: 7px 11px;

      img {
        width: 30px;
      }
    }

  }

  .blc-left {
    display: inline-block;
  }

  .blc-right {
    .cree-logement {
      box-shadow: 0 0 5px var(--primary-color);
      border-radius: 10px;

      .ant-btn-compact-first-item {
        width: 220px;
        font-size: 16px;
        border-right: 1px solid white !important;
        margin-inline-end: 0 !important;

        .icon-logement-add {
          display: inline-block;
          vertical-align: top;
          width: 30px;
          padding-top: 2px;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }
      }

      .ant-btn-compact-first-item:hover {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-last-item:hover {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-last-item {
        .icon-logement-add {
          display: inline-block;
          width: 20px;

          img {
            width: 100%;
          }
        }
      }
    }

    .ant-btn {
      border-radius: 0% !important
    }

    .ant-dropdown-trigger {
      background-color: var(--primary-color);
      color: white;
    }

    .ant-btn-compact-first-item {
      background-color: var(--primary-color);
      color: white;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .ant-btn-compact-last-item {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }

  .blc-filter {
    background-color: white;
    display: inline-block;
    padding: 3px 0px 3px 14px;
    border-radius: 10px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    margin-right: 14px;

    .ant-select-selector {
      padding: 0% 22px !important;
      border-radius: 10px !important;
      border: 0px !important;
    }

    .ant-select-selector:focus-within {
      box-shadow: 0 0 0 !important;
    }

    .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      box-shadow: none !important;
    }
  }


  .logement-table {
    .ant-table-selection-column {
      padding-left: 15px;
    }

    .ant-pagination{
      display: none;
    }

    .ant-table-thead {
      tr {
        .ant-table-cell {
          padding: 10px 16px !important;
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 600;
          color: #263754;
        }
      }
      .statusHeadColClass{
        .info-logement {
          display: inline-block;
          vertical-align: top;
          margin-left: 10px;
          width: 12px;
          height: 12px;
          padding-top: 2px;

          img {
            width: 100%;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 400;
          color: #566F8F !important;
        }
      }
    }

    .tab-option {
      cursor: pointer;
      padding-top: 10px;
      padding-left: 10px;
      border-radius: 5px;
    }

    .tab-option:hover {
      background-color: var(--tertiary-color);
    }

    .assignation-en-cour{
      padding: 0 0 0 10px;
      background-color: rgb(255, 181, 44);
      display: inline-flex;
      border-radius: 15px;
      .texte{
        display: inline-block;
        vertical-align: top;
        margin-top: 2px;
        color: black;
        font-weight: 600;
      }
      .ant-avatar{
        width: 25px;
        height: 25px;
        margin-left: 15px;
      }
    }
    .offre-envoyee{
      padding: 0 0 0 10px;
      background-color: var(--disabled-light);
      display: inline-flex;
      border-radius: 15px;
      .texte{
        display: inline-block;
        vertical-align: top;
        margin-top: 2px;
        color: black;
        font-weight: 600;
      }
      .ant-avatar{
        width: 25px;
        height: 25px;
        margin-left: 15px;
      }
    }
  }

  .tarif-reduit {
    background-color: #DBEDD0;
    border-radius: 6px;
    text-align: center;
    color: #38B962;
    font-weight: 600;
    padding: 6px 0px;
  }

  .selectBar {
    position: absolute;
    bottom: 50px;

    .contentBar {
      color: white;
      padding: 12px 10px 10px 28px;
      background-color: var(--primary-color);
      height: 50px;
      width: 850px;
      border-radius: 50px;
      font-size: 15px;
      margin-left: 41%;
      box-shadow: 0 5px 5px var(--tertiary-color);

      .selection {
        padding-top: 2px;
        vertical-align: top;
        display: inline-block;
      }

      .divider {
        margin-top: -2px;
        margin-left: 20px;
        margin-right: 20px;
        display: inline-block;
        height: 25px;
        width: 1px;
        background-color: white;
      }

      .demande-equipe {
        cursor: pointer;
        padding-top: 2px;
        vertical-align: top;
        display: inline-block;

        .image {
          vertical-align: top;
          display: inline-block;
          margin-right: 2px;
          width: 15px;
          margin-top: 2px;
          img {
            width: 100%;
          }
        }
      }
    }
  }

  .ant-select-selection-item{
    color: #213856;
    .element-filter-tab{
      .text{
        color: #213856;
      }
    }
  }
}

.surface-a-nettoyer {
  height: 100vh !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .blc-content {
    background-color: white;
    border-radius: 10px !important;
    padding: 26px 16px;
    margin-top: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .contour-plein-gris {
      margin-bottom: 10px;
      border: 1px solid rgba(128, 128, 128, 0.2);
      border-radius: 10px;
      padding: 10px;

      p {
        padding-top: 6px;
      }
    }

    .contour-point-gris {
      margin-bottom: 10px;
      border: 1px dashed rgba(128, 128, 128, 0.2);
      border-radius: 10px;
      padding: 10px;
      height: 80px;
      p {
        padding-top: 6px;
      }
    }
  }

  .surface-a-nettoyer-first-p {
    color: #5B6E8C !important;
    font-weight: 300 !important;
    font-family: 'Calibri';
  }

  .surface-a-nettoyer-second-p {
    color: #9AA1AB !important;

    p {
      color: #9AA1AB !important;
    }
  }

  input,
  textarea {
    font-family: 'Calibri';
    font-weight: 300 !important;
  }
}

.gestion-linge {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .switch-side {
    padding-top: 30px;
  }

  .longue-duree {
    float: right;
    margin-right: 5px;
    display: inline-block;

    span {
      margin-left: 15px;
    }
  }

  .info-linge {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
    margin-left: 10px;
    width: 12px;
    height: 12px;

    img {
      width: 100%;
    }
  }
}

.gestion-complete {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  height: inherit !important;
  .contour-point-gris{
    height: 30px;
  }

  .moins {
    // user-select: none;
    padding: 5.5px 3px 5.5px 5px;
    border-radius: 10px 0 0 10px;
    border: 1px solid rgba(128, 128, 128, 0.2);
    cursor: pointer;
    font-size: 12px;
  }

  .plus {
    // user-select: none;
    padding: 5.5px 6px 5.5px 2px;
    border-radius: 0 10px 10px 0;
    border: 1px solid rgba(128, 128, 128, 0.2);
    cursor: pointer;
    font-size: 12px;
  }

  .subTitle {
    margin-top: 20px;
  }

  .blc-content {
    padding-bottom: 20px !important;
  }

  .qualite-linge-image-zone {
    width: 100%;
    margin-top: 10px;
    height: 90px;
    border-radius: 10px;
    overflow: hidden;
    border: 3px solid transparent;

    .qualite-linge-image {
      width: 100%;
      background-position: center;
    }
  }

  .qualite-linge-image-zone-selected {
    border: 3px solid var(--primary-color) !important;

    .checkButton {
      display: block !important;
    }
  }

  .qualite-linge-image-zone {
    .checkButton {
      display: none;
    }
  }

  .qualite-linge-choix {
    position: relative;

    .qualite-linge-image-zone {
      cursor: pointer;
    }

    .checkButton {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 0;
      margin-left: -8px;
      margin-top: 4px;
      z-index: 999;
      background-color: white;
      border-radius: 50%;

      img {
        width: 96%;
      }
    }
  }

  .qualite-linge-label {
    margin-top: 5px !important;
  }

  .qualite-linge-description {
    margin-bottom: 10px !important;
  }
}

.vous-fournissez {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  margin-top: 30px;
  height: inherit !important;

  p {
    margin-bottom: 10px !important;
  }
}

.lavomatic {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  margin-top: 30px;
  height: inherit !important;

  p {
    margin-bottom: 10px !important;
  }

  .ant-input {
    background-color: white !important;
  }
}

/*.kit-accueil {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .blc-content {
    background-color: white;
    border-radius: 10px !important;
    padding: 26px 16px;
    margin-top: 16px;
    //desactivation de la selection du texte
    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;


    .kit-accueil-image-zone {
      width: 100%;
      height: 80px;
      border-radius: 10px;
      overflow: hidden;

      .kit-accueil-image {
        width: 100%;
      }
    }

    .description {
      padding-left: 10px;
    }
  }

  .contour-item {
    .quantity-controls {
      vertical-align: top;
      padding-top: 30px;

      .nombreDefault.st_0 {
        margin-top: 2px;
      }

      .nombreDefault {
        // user-select: none;
        width: 30px;
        height: 24px;
        display: inline-block;
        text-align: center;
        padding-top: 1px;
        padding-bottom: 0;
        border-top: 1px solid rgba(128, 128, 128, 0.2);
        border-bottom: 1px solid rgba(128, 128, 128, 0.2);

        .quantity {
          font-family: Calibri;
          color: black !important;
        }
      }

      .moins {
        // user-select: none;
        padding: 5.5px 3px 5.5px 5px;
        border-radius: 10px 0 0 10px;
        border: 1px solid rgba(128, 128, 128, 0.2);
        cursor: pointer;
        margin-top: 10px;
      }

      .plus {
        // user-select: none;
        padding: 5.5px 6px 5.5px 2px;
        border-radius: 0 10px 10px 0;
        border: 1px solid rgba(128, 128, 128, 0.2);
        cursor: pointer;
        font-size: 12px;
      }
    }
  }
}*/

.materiel-produit-menager {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .contour-item {
    margin-bottom: 10px;
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 10px;
    padding: 3px 0 8px 20px;

    p {
      padding-top: 16px;
    }

    .switch-side {
      padding-top: 16px;
    }

    .price {
      display: block;
      text-align: center;
      font-size: 1.4em;
    }

    .price-label {
      margin-top: -5px;
      display: block;
      text-align: center;
      font-size: 1.1em;
    }
  }

  .mat-border-rigth {
    padding: 7px 17px 1px 17px !important;
  }
}

.automatisation {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .contour-item {
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 10px;
    padding: 15px 15px;

    p {
      padding-top: 10px;
    }

    .plateforme-img {
      width: 45px;
      border-radius: 50%;
    }

    .plateforme-label {
      display: inline-block;
      vertical-align: top;
      font-weight: 600;
      font-size: 16px;
      padding-top: 12px;
      padding-left: 10px;
    }

    .plateforme-status {
      color: #00B050;
      vertical-align: top;
      padding-top: 12px;
      font-size: 15px;
      font-weight: 500;

      .icon-green-bold {
        color: #00B050 !important;
        font-weight: bold;
      }
    }
  }

  .contour-item-a {
    margin-top: 1px;
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 7px;
    padding: 10px 13px;
    height: 20px;
  }

  .boutton-gerer {
    padding-top: 12px;

    .contour-item-a2 {
      border: 1px solid rgba(128, 128, 128, 0.2);
      border-radius: 7px;
      padding: 10px 13px;
    }
  }


  .blc-content {
    border-radius: 10px !important;
    padding: 10px;
  }

  .button-side {
    display: flex;
    justify-content: flex-end;
  }
}

.add-automatisation-modal {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;

  .add-automatisation {
    padding: 20px;
    height: 450px;

    .title {
      font-weight: 500;
      font-size: 20px;

      .title-img {
        width: 40px;
        border-radius: 50%;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-top: 7px;
        padding-left: 10px;
      }
    }

    .select-plateforme {
      span {
        font-size: 13px;
        color: #000000ad;
      }

      .ant-select-selection-placeholder {
        color: #5B6E8C !important;
      }

      .ant-select-selector:hover {
        border-color: var(--primary-color) !important;
      }

      .ant-select-focused {
        border-color: var(--primary-color) !important;
      }

      .ant-select-selector:focus-within {
        box-shadow: 0 0 0 !important;
        border-color: var(--primary-color) !important;
      }
    }


    .ant-select-item-option-content {
      img {
        width: 60px !important;
      }
    }

    .ant-select-dropdown {
      background-color: white;
    }

    .rc-virtual-list-holder-inner {
      background-color: white;
    }

    .ant-select-selection-item {
      padding-top: 8px;

      div {
        padding: 0px 10px !important;
      }
    }

    .footer-modal {
      position: absolute;
      width: 620px;
      bottom: 30px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        background-color: var(--primary-color);
        padding: 15px 20px;
        color: white;
        font-weight: 500;
        border-radius: 10px;
      }

      .annuler {
        padding: 15px 20px;
        color: #404040;
        font-weight: 500;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }

    .connection-plateforme {
      span {
        font-size: 12px;
        color: #000000ad;
      }

      .form-control {
        width: 60%;
      }
    }

    .verifier-plateforme {
      .button-right {
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
      }

      .ant-input {
        background-color: white !important;
      }
    }

    .synchroniser-plateforme {
      .montion-bottom {
        position: absolute;
        width: 85%;
        bottom: 100px;
        text-align: center;

        span {
          font-size: 14px;
          color: #000000ad;
        }
      }
    }

    .associer-plateforme {
      .contour-item {
        margin-bottom: 10px;
        border: 1px solid rgba(128, 128, 128, 0.2);
        border-radius: 10px;
        padding: 10px;
      }
    }

    .ajouter-ical {
      .indication-lien-ical {
        font-size: 13px;
        color: var(--primary-color);
      }

      .ant-input {
        background-color: white !important;
      }
    }
  }
}


.synchonisation-dropdown {
  width: 400px;

  .ant-dropdown-menu {
    padding: 15px;
  }
}

.equipe-de-menage {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .boutton-demande {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    .add-team {
      cursor: pointer;
      border: 0;
      border-radius: 7px;
      padding: 10px 13px;
      background-color: var(--primary-color);
      color: white;
    }

    .add-team-disabled {
      cursor: pointer;
      border: 0;
      border-radius: 7px;
      padding: 10px 13px;
      background-color: var(--primary-color);
      color: white;
      opacity: 0.4;
    }
  }

  .blc-content-simple {
    border-radius: 10px !important;
    padding: 20px 20px 20px 20px;
    background-color: white;

    .contour-item {
      margin-bottom: 10px;
      margin-top: 10px;
      border: 1px solid rgba(128, 128, 128, 0.2);
      border-radius: 10px;
      padding: 5px 10px 0px 10px;

      .aidem-content {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        padding-left: 3.3px;
        padding-top: 3.3px;
        margin-bottom: 8px;
        display: inline-block;

        .aidem-img {
          width: 90%;
          height: 90%;
        }
      }


      .aidem-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
        margin-top: 6px;
        color: rgba(128, 128, 128, 0.8);
      }
    }
  }

  .blc-content {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
    padding-left: 25px !important;

    .cadre-chef {
      margin-left: 40px;
      display: inline-block;
      width: 120px;
      height: 120px;
      text-align: center;
      border-radius: 50%;

      .chef-img {
        width: 98%;
        height: 98%;
        border-radius: 10%;
        border: 0 !important;
      }
    }

    .name-chef {
      display: inline-block;
      vertical-align: top;
      padding-top: 20px;

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .subtitle {
        margin-top: -5px;
        font-size: 15px;
        color: rgba(128, 128, 128, 0.8);
        font-weight: 600;
      }
    }

    .number-chef {
      display: inline-block;
      width: 200px;
      vertical-align: top;
      padding-top: 20px;
      padding-left: 40px;

      .PhoneInputCountrySelectArrow {
        display: none;
      }

      .PhoneInputCountry {
        background-color: #f5f5f5 !important;
        margin-right: 0 !important;
        border-radius: 6px 0 0 6px !important;
        border-right: 0 !important;
      }

      .PhoneInputInput {
        padding: 9px 0 5px 0 !important;
        border-radius: 0 6px 6px 0 !important;
        border-left: 0 !important;
      }
    }

    .done {
      text-align: center;
      vertical-align: top;

      .icone-done {
        vertical-align: top;
        display: inline-block;
      }

      .text-done {
        margin-top: 7px;
        margin-left: 10px;
        display: inline-block;
        text-align: left;

        .title {
          font-size: 15px;
          font-weight: bold;
        }

        .subtitle {
          color: rgba(128, 128, 128, 0.6);
        }
      }
    }

    .steps {
      .active {
        background-color: #5AB379 !important;
        border: 3px solid #5AB379 !important;
      }

      .desactive {
        background-color: rgb(241, 240, 240) !important;
        border: 3px solid rgb(241, 240, 240) !important;
      }

      .line-active {
        background-color: #5AB379 !important;
      }

      .line-desactive {
        background-color: rgb(241, 240, 240) !important;
      }

      .step1 {
        display: inline-block;
        width: 20%;
        text-align: center;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: #5AB379;
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          padding-left: 43%;

          .circle-objet {
            width: 25px;
            height: 25px;
            border: 3px solid #5AB379;
            border-radius: 50%;
            background-color: #5AB379;
            position: relative;

            img {
              width: 100%;
            }
          }

          .line-object {
            margin-top: -15%;
            margin-left: 10%;
            width: 90%;
            height: 3px;
            background-color: #5AB379;
          }
        }
      }

      .step2 {
        display: inline-block;
        width: 20%;
        text-align: center;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: var(--primary-color);
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          .circle-objet {
            margin-left: 43%;
            width: 25px;
            height: 25px;
            border: 3px solid #DFDFDF;
            border-radius: 50%;
            position: relative;
            background-color: white;

            img {
              width: 100%;
            }
          }

          .line-object {
            margin-top: -8.5%;
            width: 100%;
            height: 3px;
            background-color: #DFDFDF;
          }
        }
      }

      .step3 {
        text-align: center;
        display: inline-block;
        width: 20%;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: var(--primary-color);
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          padding-right: 43%;

          .circle-objet {
            margin-left: 75%;
            width: 25px;
            height: 25px;
            border: 3px solid #DFDFDF;
            border-radius: 50%;
            position: relative;
            background-color: white;

            img {
              width: 100%;
            }
          }

          .line-object {
            margin-top: -15%;
            margin-right: 10%;
            width: 90%;
            height: 3px;
            background-color: #DFDFDF;
          }
        }
      }
    }

    .step-text {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 13px;
      padding-top: 15px;

      .step-text-title {
        width: 120px;
      }

      .step-text-title-1 {
        padding-left: 17%;
      }

      .step-text-title-2 {
        padding-left: 13%;
      }

      .step-text-title-3 {
        padding-left: 9%;
      }

      .step-text-title-4 {
        padding-left: 5%;
      }
    }

    .button-add {
      padding-left: 40px;
      padding-top: 30px;
      vertical-align: top;
      display: inline-block;
    }

    .leftNC {
      display: inline-block;
      text-align: center;

      .nc {
        color: rgba(128, 128, 128, 0.6);
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        display: block;
      }

      .nc-label {
        font-size: 12px;
      }

      .set {
        color: black !important;
      }
    }

    .rightNC {
      display: inline-block;
      text-align: center;
      margin-left: 50px;

      .nc {
        color: rgba(128, 128, 128, 0.6);
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        display: block;

        .rightNC-img {
          width: 19px;
          height: 19px;
          margin-left: 20px;
          vertical-align: top;
          margin-top: 1px;
        }
      }

      .nc-label {
        font-size: 12px;
      }
    }

  }

  .button-dropdown-team {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;

    .menu-img {
      width: 30px;
    }
  }

  .button-dropdown-team-alone {
    position: absolute;
    top: 15px;
    right: 0;
    margin-right: 20px;

    .menu-img {
      width: 30px;
    }
  }

  .set {
    color: black !important;
    font-weight: 500;
  }
}


.add-equipe-modal {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .add-equipe {
    padding: 20px;
    height: 450px;

    .title {
      font-weight: 500;
      font-size: 20px;

      .title-img {
        width: 40px;
        border-radius: 50%;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-top: 7px;
        padding-left: 10px;
      }
    }

    .dateSelect {
      font-size: 13px;
    }

    .info-equipe {
      position: absolute;
      right: 10px;
      width: 12px;
      height: 12px;
      top: 2px;

      img {
        width: 100%;
      }
    }

    .ant-picker {
      margin-top: 3px;
    }

    .ant-picker-outlined:hover {
      border-color: var(--primary-color) !important;
    }

    .ant-picker-outlined:focus-within {
      border-color: var(--primary-color) !important;
      box-shadow: 0 0 0 !important;
    }

    .steps {
      .step1 {
        display: inline-block;
        width: 33%;
        text-align: center;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: var(--primary-color);
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          padding-left: 43%;

          .circle-objet {
            width: 25px;
            height: 25px;
            border: 3px solid var(--primary-color);
            border-radius: 50%;
            background-color: white;
            position: relative;
          }

          .line-object {
            margin-top: -15%;
            margin-left: 10%;
            width: 90%;
            height: 2px;
            background-color: var(--primary-color);
          }
        }
      }

      .step2 {
        display: inline-block;
        width: 33%;
        text-align: center;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: var(--primary-color);
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          .circle-objet {
            margin-left: 43%;
            width: 25px;
            height: 25px;
            border: 3px solid rgba(128, 128, 128, 0.6);
            border-radius: 50%;
            position: relative;
            background-color: white;
          }

          .line-object {
            margin-top: -8.5%;
            width: 100%;
            height: 2px;
            background-color: rgba(128, 128, 128, 0.6);
          }
        }
      }

      .step3 {
        text-align: center;
        display: inline-block;
        width: 33%;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: var(--primary-color);
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          padding-right: 43%;

          .circle-objet {
            margin-left: 75%;
            width: 25px;
            height: 25px;
            border: 3px solid rgba(128, 128, 128, 0.6);
            border-radius: 50%;
            position: relative;
            background-color: white;
          }

          .line-object {
            margin-top: -15%;
            margin-right: 10%;
            width: 90%;
            height: 2px;
            background-color: rgba(128, 128, 128, 0.6);
          }
        }
      }
    }

    .step-text-title {
      display: block;
      text-align: center;
      padding-top: 20px;
      font-size: 14px;
      font-weight: 600;
    }

    .step-text-describe {
      padding: 0 10px;
      display: block;
      text-align: center;
      padding-top: 5px;
      font-size: 11px;
      font-weight: 400;
      color: rgba(128, 128, 128, 0.9);
    }

    .ant-picker-input {
      background-color: white;

      input {
        background-color: white !important;
      }
    }

    .footer-modal {
      position: absolute;
      width: 620px;
      bottom: 30px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        background-color: var(--primary-color);
        padding: 15px 20px;
        color: white;
        font-weight: 500;
        border-radius: 10px;
      }

      .annuler {
        padding: 15px 20px;
        color: #404040;
        font-weight: 500;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }
}

.prestation-planning {
  .planning-table {
    .ant-table-selection-column {
      padding-left: 15px;
    }

    .ant-table-thead {
      tr {
        .ant-table-cell {
          padding: 10px 16px !important;
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 600;
          color: #263754;
        }
      }
    }

    tbody {
      tr {
        td {
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 400;
          color: #566F8F !important;
        }
      }
    }
  }

  .cree-logement {
    box-shadow: 0 0 5px var(--primary-color);
    border-radius: 10px;

    .ant-btn-compact-first-item {
      width: 220px;
      font-size: 16px;
      border-right: 1px solid white !important;
      margin-inline-end: 0 !important;

      .icon-logement-add {
        display: inline-block;
        vertical-align: top;
        width: 30px;
        padding-top: 2px;
        margin-right: 0px;

        img {
          width: 100%;
        }
      }
    }

    .ant-btn-compact-first-item:hover {
      background-color: var(--primary-color);
      color: white;
    }

    .ant-btn-compact-last-item:hover {
      background-color: var(--primary-color);
      color: white;
    }

    .ant-btn-compact-last-item {
      .icon-logement-add {
        display: inline-block;
        width: 20px;

        img {
          width: 100%;
        }
      }
    }
  }

  .blc-filter {
    background-color: white;
    display: inline-block;
    padding: 3px 0px 3px 14px;
    border-radius: 10px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    margin-right: 14px;

    .ant-select-selector {
      padding: 0% 22px !important;
      border-radius: 10px !important;
      border: 0px !important;
    }

    .ant-select-selector:focus-within {
      box-shadow: 0 0 0 !important;
    }

    .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      box-shadow: none !important;
    }
  }

  .blc-right {
    padding-top: 0 !important;
    height: auto;

    .ant-btn {
      border-radius: 0% !important
    }

    .ant-dropdown-trigger {
      background-color: var(--primary-color);
      color: white;
    }

    .ant-btn-compact-first-item {
      background-color: var(--primary-color);
      color: white !important;
      border-radius: 10px 0px 0px 10px !important;
    }

    span {
      color: white !important;
    }

    .ant-btn-compact-last-item {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }
  .ant-select-selection-item{
    color: #213856 !important;
    .element-filter-tab{
      .text{
        color: #213856 !important;
      }
    }
  }
}

.empty-Tab-planning {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 100px;

  .plateforme-img {
    width: 40px;
    border-radius: 10%;
  }

  .title {
    color: black;
    font-weight: 600;
    font-size: 23px !important;
  }

  p {
    font-size: 13px !important;
    padding-bottom: 6px !important;
  }

  a {
    font-size: 13px !important;
  }

  .add-prestation {
    cursor: pointer;
    border: 0;
    border-radius: 7px;
    padding: 10px 13px;
    background-color: var(--primary-color);
    color: white;
  }
}

.empty-Tab-planning-prestation {
  text-align: center;
  padding-top: 0;
  padding-bottom: 10px;

  .plateforme-img {
    width: 150px;
    border-radius: 10%;
  }

  .title {
    color: var(--disabled);
    font-weight: 300;
    font-size: 14px !important;
  }

  p {
    font-size: 13px !important;
    padding-bottom: 6px !important;
  }

  a {
    font-size: 13px !important;
  }

  .add-prestation {
    cursor: pointer;
    border: 0;
    border-radius: 7px;
    padding: 10px 13px;
    background-color: var(--primary-color);
    color: white;
  }
}

.add-logement-item-1 {
  margin-top: 10px;
}

.add-logement-item-2:hover {
  background-color: transparent !important;
}

.add-logement-item-2 {
  margin-bottom: 8px;
}

.add-logement-item {
  padding-top: 5px;
  width: 230px;
  padding-left: 20px;
  color: var(--text-grey-color);

  .imageSide {
    display: inline-block;
    width: 20px;
    vertical-align: top;
    padding-right: 10px;

    img {
      width: 100%;
    }
  }
}

.add-logement2-item {
  padding-top: 5px;
  width: 250px;
  padding-left: 0;
  color: var(--text-grey-color);

  .imageSide.reduction-taille {
    width: 17px;
  }

  .imageSide {
    display: inline-block;
    width: 20px;
    vertical-align: top;
    padding-right: 10px;

    img {
      width: 100%;
    }
  }
}
.add-logement3-item {
  padding-top: 5px;
  width: 170px;
  padding-left: 0;
  color: black;
  text-align: right;
  .imageSide.reduction-taille {
    width: 17px;
  }

  .imageSide {
    display: inline-block;
    width: 20px;
    vertical-align: top;
    padding-right: 10px;

    img {
      width: 100%;
    }
  }
}
.add-logement4-item {
  padding-top: 5px;
  width: 100px;
  padding-left: 0;
  color: black;
  text-align: right;
  .imageSide.reduction-taille {
    width: 17px;
  }

  .imageSide {
    display: inline-block;
    width: 20px;
    vertical-align: top;
    padding-right: 10px;

    img {
      width: 100%;
    }
  }
}

.add-controler-prestation{
  padding-top: 5px;
  width: 170px;
  padding-left: 0;
  color: black;

  .imageSide.reduction-taille {
    width: 17px;
  }
  .imageSide {
    display: inline-block;
    width: 20px;
    vertical-align: top;
    padding-right: 10px;

    img {
      width: 100%;
    }
  }
}


.ant-select-dropdown {
  border: 1px solid var(--primary-color) !important;
}

.disable-primary-button {
  background-color: var(--tertiary-color) !important;
  cursor: default !important;
}

.logement-add-drompdown {
  padding-top: 5px;
}

.search-input{
  border-color: var(--disabled-light) !important;
}

.record-logment{
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400 !important;
  .head{
    position: relative;
    height: 200px;
    background-color: rgb(144, 206, 252);
    .date{
      color: white;
      position: absolute;
      right: 20px;
      top: 35px;
    }
    .type{
      position: absolute;
      right: -10px;
      top: 65px;
      width: 150px;
      height: 30px;
      padding: 0  0 0 20px;
      border-radius: 30px;
      background-color: white;
      .icone{
        vertical-align: top;
        margin-top: 4px;
        margin-right: 15px;
        display: inline-block;
        width: 20px;
        img{
          width: 100%;
        }
      }
      .text{
        display: inline-block;
        vertical-align: top;
        padding-top: 5px;
      }
    }
  }
  .photo{
    padding: 0 30px;
    margin-top: -46px;
    position: relative;
    .ant-avatar{
      background-color: white;
      width: 89px;
      height: 90px;
    }
    .infication-logement{
      position: absolute;
      left: 100px;
      top: 25px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgb(255, 209, 102);
      text-align: center;
      img{
        margin-top: 9px;
        width: 50%;
      }
    }
  }
  .idLogement{
    float: right;
    margin-right: 40px;
    font-size: 18px;
    color: var(--disabled);
    margin-top: -30px;
  }
  .content{
    padding-top: 10px;
    padding-bottom: 20px;
    .menu{
      color: black;
      cursor: pointer;
      vertical-align: top;
      margin-top: 0;
      font-size: 16px;
      float: right;
      width: 28px;
      text-align: center;
      padding-top: 2px;
      border-radius: 5px;
    }
    .info{
      .name{
        position: relative;
        .point-vert{
          width: 10px;
          height: 10px;
          background-color: limegreen;
          display: inline-block;
          border-radius: 50%;
          margin-top: 11px;
          margin-right: 10px;
          vertical-align: top;
        }
        .nom{
          display: inline-block;
          font-size: 25px;
        }
        .sigle{
          display: inline-block;
          margin-left: 10px;
          padding: 3px;
          font-size: 25px;
          text-align: center;
          color: var(--disabled);
          border-radius: 10px;
          .etoile{
            display: inline-block;
            vertical-align: top;
            margin-top: 5px;
          }
        }
        .menu:hover{
          background-color: var(--secondary-color);
        }
        .pays{
          margin-top: -5px;
          font-size: 16px;
          color: grey;
        }
      }
      .info-supp.first{
        margin-bottom: 25px;
      }
      .info-supp{
        margin-top: 12px;
        .icone-enveloppe{
          position: relative;
          display: inline-block;
          width: 36px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid var(--disabled-light);
          text-align: center;
          padding: 8px 0;
          img{
            width: 50%;
          }
          .counter{
            position: absolute;
            bottom: -5px;
            right: -8px;
            width: 20px;
            height: 20px;
            font-size: 13px;
            vertical-align: top;
            padding-top: 1px;
            background-color: var(--disabled-light);
            border-radius: 50%;
          }
        }
        .icone-call{
          display: inline-block;
          margin-left: 20px;
          width: 36px;
          border-radius: 50%;
          border: 1px solid var(--disabled-light);
          text-align: center;
          padding: 8px 0;
          position: relative;
          img{
            width: 50%;
          }
          .counter{
            position: absolute;
            bottom: -5px;
            right: -8px;
            width: 20px;
            height: 20px;
            font-size: 13px;
            vertical-align: top;
            padding-top: 1px;
            background-color: var(--disabled-light);
            border-radius: 50%;
          }
        }
        .paiement{
          display: inline-block;
          margin-left: 20px;
          border: 1px solid var(--disabled-light);
          border-radius: 11px;
          .icone-paiement{
            display: inline-block;
            margin-left: 5px;
            width: 40px;
            background-color: var(--disabled-light);
            border-radius: 5px;
            text-align: center;
            padding: 4px 0;
            img{
              width: 30%;
            }
          }
          .text{
            margin-left: 15px;
            display: inline-block;
          }
          .icone-close{
            display: inline-block;
            margin-left: 20px;
            width: 30px;
            border-radius: 50%;
            text-align: center;
            padding: 8px 0;
            img{
              width: 50%;
            }
          }
        }
        .button-info-supp{
          display: inline-block;
          margin-left: 20px;
          button{
            vertical-align: top;
            margin-top: -9px;
            border: 1px solid var(--disabled) !important;
            height: 30px !important;
            border-radius: 7px!important;
          }
        }
      }
      .logement-client{
        margin-top: 60px;
        .titre{
          .text{
            display: inline-block;
            font-size: 17px;
          }
          .indice{
            display: inline-block;
            margin-left: 5px;
          }
        }
        .contenu{
          margin-top: 30px;
          .element{
            display: table-cell;
            vertical-align: middle;
            width: 110px;
            margin-right: 20px;
            text-align: center;
            .Himage{
              display: flex;
              justify-content: center;
              text-align: center;
              .image{
                width: 50px;
                border-radius: 50%;
                background-color: var(--disabled-super-light);
                border: 1px solid var(--disabled);
                text-align: center;
                padding: 11px 0;
                img{
                  width: 50%;
                }
              }
            }
            .label{
              margin-top: 5px;
              text-align: center;
            }
          }
        }
      }
      .verticalDivider{
        width: 1px;
        background-color: var(--disabled-light);
        height: 90px;
      }
    }
    .service{
      .title{
        color: var(--disabled);
      }
      .section{
      font-size: 15px;
      }
      .data{
        margin-top: 10px;
        font-size: 18px;
        .image{
          cursor: pointer;
          width: 20px;
          float: right;
          margin-right: 35px;
          img{
            width: 100%;
          }
        }
      }
      .indication-lavage{
        background-color: var(--disabled-super-light);
        display: inline-block;
        float: right;
        margin-top: 10px;
        border-radius: 10px;
        padding: 3px 10px;
      }
      .diamand{
        margin-top: 8px;
        display: inline-block;
        float: right;
        vertical-align: top;
        img{
          width: 20px;
        }
      }
      .kitmore{
        float: left;
        margin-top: 7px;
        .kitLinge{
          float: left;
          width: 25px;
          height: 25px;
          background-color: var(--primary-color);
          border-radius: 50%;
          text-align: center;
          margin-right: 7px;
          img{
            width: 15px;
            vert-align: top;
            margin-top: 5px;
          }
        }
        .kitGlobalValue{
          margin-right: 10px;
          float: left;
          vertical-align: top;
          font-size: 16px;
          margin-top: 2px;
        }
        .kitProduitMenager{
          margin-right: 7px;
          float: left;
          width: 25px;
          height: 25px;
          background-color: var(--primary-color);
          border-radius: 50%;
          text-align: center;
          img{
            width: 15px;
            vert-align: top;
            margin-top: 5px;
          }
        }
      }

      .verticalDivider{
        display: inline-block;
        vertical-align: top;
        margin-right: 100px;
        height: 50px;
        width: 1px;
        background-color: var(--disabled-light);
      }
    }
    .kit{
      .title{
        color: black;
        .sub{
          display: inline;
          color: var(--disabled)
        }
      }
      .info-supp{
        margin-top: 12px;
        .iconHead{
          display: flex;
          justify-content: center;
          .icone{
            overflow: hidden;
            position: relative;
            display: inline-block;
            width: 50px;
            height: 27px;
            border-radius: 50%;
            border: 1px solid var(--disabled);
            background-color:  #F9FAFB;
            text-align: center;
            padding: 10px 0;
            img{
              margin-top: 1px;
              width: 50%;
            }
          }
        }
        .label{
          padding: 3px 2px;
          font-size: 11px;
          text-align: center;
        }
      }
      .info-ical{
        .element{
          margin-bottom: 5px;
          .head{
            display: inline-block;
            height: auto;
            background-color: transparent;
            color: grey;
            font-weight: 400;
          }
          .contender{
            padding-left: 15px;
            display: inline-block;
            a{
              color: #0a94ff;
            }
          }
          .action{
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
            margin-top: 1px;
          }
          .action:hover{
            color: red !important;
          }
        }
      }
      .blc-content-simple {
        border-radius: 10px !important;
        background-color: white;
        .assignation-en-cour{
          padding: 0 15px 0 10px;
          background-color: rgb(255, 181, 44);
          display: inline-flex;
          border-radius: 15px;
          .texte{
            display: inline-block;
            vertical-align: top;
            margin-top: 5px;
            color: black;
            font-weight: 600;
          }
          .ant-avatar{
            width: 25px;
            height: 25px;
            margin-left: 15px;
          }
        }
        .offre-envoyee{
          padding: 0 15px 0 10px;
          background-color: var(--disabled-light);
          display: inline-flex;
          border-radius: 15px;
          .texte{
            display: inline-block;
            vertical-align: top;
            margin-top: 5px;
            color: black;
            font-weight: 600;
          }
          .ant-avatar{
            width: 25px;
            height: 25px;
            margin-left: 15px;
          }
        }
        .contour-item.colored {
          border: 1px solid var(--primary-color);
        }
        .contour-item {
          margin-bottom: 10px;
          margin-top: 10px;
          border: 1px solid rgba(128, 128, 128, 0.2);
          border-radius: 10px;
          padding: 10px 10px 10px 10px;

          .aidem-content {
            width: 27px;
            height: 27px;
            border-radius: 50%;
            display: inline-block;
              vertical-align: top;
            .aidem-img {
              width: 100%;
              height: 100%;
            }
          }


          .aidem-label {
            display: inline-block;
            vertical-align: top;
            padding-left: 10px;
            margin-top: 6px;
            color: rgba(128, 128, 128, 0.8);
          }
          .aidem-tag{
            vertical-align: top;
            padding-left: 10px;
            margin-top: 6px;
            float: right;
          }
        }
      }
    }
  }
}

.editer-forfait{
  .informations{
    .ant-modal-content{
      border-radius: 25px !important;
      padding: 15px 25px;
    }
    input{
      font-family: Calibri, serif;
      font-weight: 300;
    }

    .contentIfon {
      padding: 15px 20px 15px 20px;
      height: 330px;
      position: relative;
      .title{
        margin-bottom: 40px;
        .icone{
          vertical-align: top;
          display: inline-block;
          width: 20px;
          margin-top: 7px;
          img{
            width: 100%;
          }
        }
        .text{
          //padding-left: 10px;
          display: inline-block;
          font-size: 20px;
          margin-bottom: 15px;
        }
      }

      .form-edit-profil{
        label{
          color: grey;
        }
        .input-barrier{
          position: relative;
          input{
            height: 45px;
            background-color: white !important;
            font-size: 19px;
            font-weight: 300 !important;
            text-align: right;
            vertical-align: top;
            padding-right: 60px;
          }
          .label-effet{
            position: absolute;
            left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            top: 15px;
            font-size: 15px;
            z-index: 999;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
          .force-label-effet{
            position: absolute;
            left: 5px;
            top: -11px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
          .percent{
            position: absolute;
            right: 2px;
            top: 2px;
            background-color: var(--disabled-light);
            height: 34px;
            width: 41px;
            font-weight: 600;
            padding-top: 7px;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            vertical-align: top;
            text-align: center;
            border-radius: 5px;
          }
        }
        .input-barrier-number{
          position: relative;
          .ant-form-item-control-input-content{
            border: 1px solid #d9d9d9;
            border-radius: 7px;
            height: 55px;
            background-color: white !important;
            font-size: 19px;
            .PhoneInputCountrySelectArrow {
              display: none;
            }

            .PhoneInputCountry {
              background-color: white !important;
              margin-right: 0 !important;
              border: 0 !important; ;
              padding: 8px 10px 5px 15px !important;
              border-radius: 6px 0 0 6px !important;
              border-right: 0 !important;
            }

            .PhoneInputInput {
              font-size: 17px;
              background-color: white !important;
              padding: 13px 0 5px 0 !important;
              border-radius: 0 6px 6px 0 !important;
              border: 0 !important;
            }
          }
          .label-effet{
            position: absolute;
            left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            top: 15px;
            font-size: 15px;
            z-index: 999;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
          .force-label-effet{
            position: absolute;
            left: 5px;
            top: -11px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
        }
        .input-barrier:focus-within{
          .label-effet{
            position: absolute;
            left: 5px;
            top: -11px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
        }
      }
    }
    .footer-modal {
      position: absolute;
      width: 90%;
      bottom: 20px;

      .button-left {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
      }

      .button-right {
        padding-left: 15px;
        display: flex;
        justify-content: flex-end;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;

        span {
          color: black;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid black;
        }
      }

      .suivant {
        height: 30px;
        background-color: var(--primary-color);
        padding: 10px 20px 10px 20px;
        color: white;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        border-radius: 7px;
        width: 100%;
        //box-shadow: 0 0 5px var(--primary-color);
      }
      .disable{
        background-color: rgba(241,240,240,1) !important;
      }
      .modifier {
        height: 25px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }
    }
  }
}

.status-classe-select-responsable{
  top: 270px !important;
  left: 455px !important;
  width: 200px !important;
  .element-filter-tab{
    .icone{
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 1px;
      img{
        width: 100%;
      }
    }
    .text{
      display: inline-block;
      vertical-align: top;
    }
  }
}
.status-classe-select-responsable-in-modal{
  top: 270px !important;
  left: 375px !important;
  width: 200px !important;
  .element-filter-tab{
    .icone{
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 1px;
      img{
        width: 100%;
      }
    }
    .text{
      display: inline-block;
      vertical-align: top;
    }
  }
}

.status-classe-select-clinet-logement{
  top: 270px !important;
  left: 630px !important;
  width: 200px !important;
  .element-filter-tab{
    .icone{
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 1px;
      img{
        width: 100%;
      }
    }
    .text{
      display: inline-block;
      vertical-align: top;
    }
  }
}
.status-classe-select-clinet-ville{
  top: 270px !important;
  left: 765px !important;
  width: 150px !important;
  .element-filter-tab{
    .icone{
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 1px;
      img{
        width: 100%;
      }
    }
    .text{
      display: inline-block;
      vertical-align: top;
    }
  }
}
.status-classe-select-clinet-ville-in-modal{
  top: 270px !important;
  left: 545px !important;
  width: 150px !important;
  .element-filter-tab{
    .icone{
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 1px;
      img{
        width: 100%;
      }
    }
    .text{
      display: inline-block;
      vertical-align: top;
    }
  }
}
.status-classe-select-prestation-types-in-modal{
  top: 370px !important;
  left: 645px !important;
  width: 200px !important;
  .element-filter-tab{
    .icone{
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 1px;
      img{
        width: 100%;
      }
    }
    .text{
      display: inline-block;
      vertical-align: top;
    }
  }
}
.equipe-statut-classe-site{
  top: 330px !important;
  left: 895px !important;
  width: 200px !important;
  .element-filter-tab{
    .icone{
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 1px;
      img{
        width: 100%;
      }
    }
    .text{
      display: inline-block;
      vertical-align: top;
    }
  }
}