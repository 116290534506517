.ant-layout-header {
    background-color: white;
    height: 90px;
}

.search-input {
    background-color: white;
    height: 40px;

    input {
        background-color: white !important;
    }
}


.topBarSearch{
    padding-left: 8%;
    padding-top: 10px;
    .search-input:hover {
        border-color: transparent !important;
        box-shadow: 0 0 0 !important;
    }
    .search-input:focus {
        border-color: transparent !important;
        box-shadow: 0 0 0 !important;
    }
    .search-input {
        background-color: rgb(245,246,248);
        height: 40px;
        border-color: transparent !important;
        box-shadow: 0 0 0 !important;
        input {
            background-color: rgb(246,248,250) !important;
        }
        input::placeholder {
            color: #616B7B !important;
        }
    }
    .slash{
        width: 23px;
        height: 23px;
        background-color: #E5E8EC;
        border-radius: 4px;
        padding-left: 10px;
        div{
            transform: rotate(10deg);
            opacity: 0.4;
        }
    }
}

.icons-head {
    font-size: 20px;
    cursor: pointer;
    svg {
        margin: 0px 8px;
    }
}

.headerBarClass{
    .resultResearch{
        margin-left: 7%;
        padding: 25px 25px;
        z-index: 999;
        margin-top: 5px;
        position: absolute;
        border-radius: 10px;
        background-color: white;
        width: 100%;
        box-shadow: 1px 1px 5px 5px var(--disabled);
        .ant-tabs-tab:hover{
            color: var(--primary-color) !important;
        }
        .ant-tabs-tab{
            padding: 8px 20px;
            font-size: 15px;
        }
        .ant-tabs-tab+ .ant-tabs-tab{
            margin: 0 0 0 70px;
        }
        .ant-tabs-tab-btn{
            color: #A6A6A6;
            font-size: 20px;
            font-weight: 600;
            .nbResult{
                text-align: center;
                .nbResult-in{
                    display: inline-block;
                    background-color: var(--disabled-light);
                    padding: 1px 10px;
                    border-radius: 5px;
                    font-size: 17px;
                    margin-bottom: 25px;
                }
            }
        }
        .ant-tabs-tab-active{
            .ant-tabs-tab-btn{
                color: var(--primary-color) !important;
            }
        }
        .ant-tabs-ink-bar{
            background-color: var(--primary-color) !important;
        }
    }
    .resultZone{
        padding: 20px 15px;
        .empty{
            color: grey;
            font-weight: 600;
            font-size: 18px;
        }
    }
}