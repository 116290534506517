@import './components/button';
// @import './components/modal';
@import './components/input';
@import './components/message';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

html {
    --primary-color: #ff7f0a;
    --secondary-color: #FDF7F2;
    --tertiary-color: #FBE0D1;
    --white-color: #FFFFFF;
    --bg-color: rgb(246, 247, 249);
    --yellow-color: #EBA348;
    --text-color: #494949;
    --text-gray-color: #1b2f41e0;
    --text-grey-color: #566F8F;
    --gray-color: #4949490a;
    --line-graycolor: #f0f0f0;
    --source-sans-pro-light: 300;
    --disabled: rgba(128, 128, 128, 0.4196078431);
    --disabled-light: #EDEDED;
    --disabled-super-light: rgb(248, 249, 250);
}

@font-face {
    font-family: 'Calibri';
    src: url('../../public/assets/font/calibri/Calibri.woff2') format('woff2'),
        url('../../public/assets/font/calibri/Calibri.woff') format('woff'),
        url('../../public/assets/font/calibri/Calibri.ttf') format('ttf');
}

body {
    padding: 0;
    margin: 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    position: relative;
    color: var(--text-gray-color) !important;
}

h1,
h3,
h3,
h4,
h5,
h6 {
    font-family: 'Source Sans Pro', sans-serif;
}

p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    color: var(--text-gray-color) !important;
    font-size: 15px;
}

main {
    height: 100vh !important;
    overflow: hidden;
    overflow-y: scroll;
}

.ant-layout {
    background: #FBFBFB !important;
}

.flex {
    display: flex;
}

.align-items {
    align-items: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.container {
    max-width: 1560px;
    margin-left: auto;
    margin-right: auto;
}

.content {
    padding: 30px;
}

.icon {
    margin-bottom: -2px;
}

.css-1wy0on6 {
    display: none !important;
}

.ant-tag {
    color: white;
}

.ant-col {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 400 !important;
}

::placeholder {
    color: #80808078;
}

.edit {
    ::placeholder {
        color: black !important;
    }
}

.ant-upload-list {
    display: none;
}

.ant-input,
input,
textarea {
    font-family: Calibri, serif !important;
}

.ant-table-selection-extra {
    display: none;
}

.border-bottom-only {
    border-bottom: 1px solid var(--line-graycolor);
}

.mb-1em {
    margin-bottom: 1em;
}

.mb-10px {
    margin-bottom: 10px;
}

.text-align-center {
    text-align: center;
}

.ant-dropdown-trigger>.anticon.anticon-down {
    font-size: 15px;
    vertical-align: middle;
}

.footer-login {
    text-align: center;
    position: initial;
    bottom: 10px;
    width: 100%;
    font-size: 0.9em;

    .text-align-center {
        color: var(--text-gray-color);
    }
}


.header-page {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: xx-large;
        font-weight: 600;
        margin-bottom: 18px;
        margin-top: -10px;
    }

    .sub-title {
        color: var(--text-gray-color);
    }
}

.disable {
    color: #8080806b !important;
    background: white !important;
    border-color: #8080806b !important;
}

.disable:hover {
    border: none;
}

// DETAIL PAGE 
.detail-page {
    .header-detail-page {
        display: inline;

        .title {
            font-size: xx-large;
            font-weight: 600;
        }
    }

    .content-detail-page {

        .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
        .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
            display: inline-block !important;
            flex: none !important;
        }

        .ant-tabs>.ant-tabs-nav,
        .ant-tabs>div>.ant-tabs-nav {
            position: static;
        }

        .ant-tabs-nav {
            border-bottom: 1px solid var(--line-graycolor);
        }

        .sub-title {
            color: var(--text-gray-color);
        }

        .ant-btn-icon-only {
            border: 0px solid transparent;
        }

        .action-button-tabs {
            background-color: var(--primary-color);
            color: white;
            position: absolute;
            right: 0;
            margin-top: -5px;
        }

    }


    .collapse-liste {
        width: 80%;

        .ant-collapse-header-text {
            font-weight: 600;
        }

        .ant-collapse-header {
            border-bottom: 1px solid var(--line-graycolor);
            padding-left: 0px;
            padding-bottom: 5px;
            margin-top: 10px;
        }

        .sub-title {
            color: var(--text-gray-color);
        }

        .total-depense {
            font-size: 1.5em;
        }

        .simple-sub-title-big {
            font-size: 1.3em;
        }
    }

    .customer-detail-page-button {
        color: var(--primary-color);
    }
}

.light-border-bottom {
    border-bottom: 1px solid var(--line-graycolor);
}

// TABLE
.ant-table-thead>tr>th {
    background: white;
    border-top: 1px solid #f0f0f0;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: white;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 10px 0px;
}

.ant-pagination-item-active {
    border-color: var(--primary-color);
}

.ant-pagination-item-active a {
    color: var(--primary-color);
}

.ant-pagination-options {
    display: none;
}

.action-button {
    height: 20px !important;

    img {
        vertical-align: initial;
        width: 15px;
        height: 4.2px;
    }
}

.ant-pagination {
    float: right;
}

.ant-table-tbody {
    .ant-table-row {
        .ant-table-cell {
            color: var(--text-color);

            a {
                color: var(--text-color);
            }
        }
    }
}

th {
    font-weight: 600 !important;
}

// TABS
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
}

.ant-tabs-tab:hover {
    color: var(--primary-color);
}

.ant-tabs-ink-bar {
    background-color: var(--primary-color);
}

.table-client-page {
    .ant-table-thead>tr>th {
        font-weight: bold;
    }
}

// TAB
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
}

.ant-tabs-tab:hover {
    color: var(--primary-color);
}

.ant-tabs-ink-bar {
    background-color: var(--primary-color);
}

.ant-tabs-top>.ant-tabs-nav {
    // margin: 0 0 10px 0;
    margin: 0px;
}

.ant-tabs-tab {
    padding-top: 0px;
}

// TAG
.ant-tag {
    border: 0px;
    border-radius: 10px;
}


// DROPDOWN MENU
.ant-dropdown-menu {
    border-radius: 5px;
}

// BUTTON
.ant-btn {
    border: 0px;
    box-shadow: 0px 0px;
}


//COLLAPSE
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    vertical-align: 0px;
}

.divider {
    height: 1px;
    background-color: var(--line-graycolor);
}


.disabled-block {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
}

.ant-divider-horizontal {
    margin: 18px 0;
}


/* SPINNER */
.ant-spin-dot-item {
    background: var(--primary-color) !important;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
    color: var(--primary-color) !important;
}


.moins {
    user-select: none;
    padding: 5.5px 3px 5.5px 5px;
    border-radius: 10px 0 0 10px;
    border: 1px solid rgba(128, 128, 128, 0.2);
    cursor: pointer;
    font-size: 12px;
}

.plus {
    user-select: none;
    padding: 5.5px 6px 5.5px 2px;
    border-radius: 0 10px 10px 0;
    border: 1px solid rgba(128, 128, 128, 0.2);
    cursor: pointer;
    font-size: 12px;
}

.nombre {
    user-select: none;
    width: 120px;
    display: inline-block;
    vertical-align: inherit;
    margin-bottom: 0;
    text-align: center;

    .nombre-input:hover {
        border-color: rgba(255, 127, 10, 0.3) !important;
    }

    .nombre-input:focus-within {
        border-color: rgba(255, 127, 10, 0.3) !important;
        box-shadow: 0 0 0;
    }

    .nombre-input {
        height: 35px;
        text-align: center;
        background-color: white !important;
        border-radius: 10px;
        border: 1px solid rgba(128, 128, 128, 0.2);

        .ant-input {
            background-color: white !important;
            padding-top: 5px;
        }
    }

    .valeur {
        background-color: var(--tertiary-color);
        width: 35px;
        height: 24px;
        margin-right: 5px;
        border-radius: 7px;
        font-size: 15px;
        padding-left: 2px;
        padding-top: 1px;
        margin-left: -6px;
    }
}

.nombre4 {
    user-select: none;
    width: 40px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    text-align: center;

    .ant-input {
        border-radius: 0 !important;
    }

    .nombre-input:hover {
        border-color: rgba(255, 127, 10, 0.3) !important;
    }

    .nombre-input:focus-within {
        border-color: rgba(255, 127, 10, 0.3) !important;
        box-shadow: 0 0 0;
    }

    .nombre-input {
        vertical-align: top !important;
        margin-top: -2px;
        height: 28px;
        text-align: center;
        background-color: white !important;
        border-top: 1px solid rgba(128, 128, 128, 0.2);
        border-bottom: 1px solid rgba(128, 128, 128, 0.2);
        border-left: 0;
        border-right: 0;

        .ant-input {
            background-color: white !important;
            padding-top: 5px;
        }
    }

    .nombre-input-alter {
        vertical-align: top !important;
        margin-top: -3px;
        height: 28px;
        text-align: center;
        background-color: white !important;
        border-top: 1px solid rgba(128, 128, 128, 0.2);
        border-bottom: 1px solid rgba(128, 128, 128, 0.2);
        border-left: 0;
        border-right: 0;

        .ant-input {
            background-color: white !important;
            padding-top: 5px;
        }
    }

    .valeur {
        background-color: var(--tertiary-color);
        width: 35px;
        height: 24px;
        margin-right: 5px;
        font-size: 15px;
        padding-left: 2px;
        padding-top: 1px;
        margin-left: -6px;
    }
}

.nombre2 {
    user-select: none;
    width: 50px;
    display: inline-block;
    vertical-align: inherit;
    margin-bottom: 0;
    text-align: center;

    .nombre-input:hover {
        border-color: rgba(128, 128, 128, 0.2) !important;
    }

    .nombre-input:focus-within {
        border-color: rgba(128, 128, 128, 0.2) !important;
        box-shadow: 0 0 0;
    }

    .nombre-input {
        border-radius: 0 !important;
        height: 27px;
        text-align: center;
        background-color: white !important;
        border-left: 0;
        border-right: 0;
        border-top: 1px solid rgba(128, 128, 128, 0.2);
        border-bottom: 1px solid rgba(128, 128, 128, 0.2);
        padding-top: 7px;
    }

    .quantity {
        font-family: Calibri;
        color: black !important;
    }
}

.nombre3 {
    user-select: none;
    width: 50px;
    display: inline-block;
    vertical-align: inherit;
    margin-bottom: 0;
    text-align: center;

    .nombre-input:hover {
        border-color: rgba(128, 128, 128, 0.2) !important;
    }

    .nombre-input:focus-within {
        border-color: rgba(128, 128, 128, 0.2) !important;
        box-shadow: 0 0 0;
    }

    .nombre-input {
        border-radius: 0 !important;
        height: 26px;
        text-align: center;
        background-color: white !important;
        border-left: 0;
        border-right: 0;
        border-top: 1px solid rgba(128, 128, 128, 0.2);
        border-bottom: 1px solid rgba(128, 128, 128, 0.2);
        padding-top: 7px;
    }
}

.disable-Button {
    background-color: rgba(128, 128, 128, 0.1);
}

a,
a:hover,
a:focus {
    color: var(--primary-color);
}

a.noColor {
    color: black;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-switch.ant-switch-checked {
    background-color: var(--primary-color) !important;
}

.ant-switch-checked {
    background-color: var(--primary-color) !important;
}

.icon-primary-bold {
    color: var(--primary-color) !important;
    font-weight: bold;
}

.dropdown-item-team {
    .dropdown-item-team-img {
        width: 30px;
        height: 30px;
    }

    .dropdown-item-team-label {
        display: inline-block;
        vertical-align: top;
        padding-top: 5px;
        padding-left: 10px;
    }
}

.primary-color {
    color: var(--primary-color) !important;
}

:where(.css-mzwlov).ant-table-wrapper .ant-table-cell,
:where(.css-mzwlov).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-mzwlov).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-mzwlov).ant-table-wrapper .ant-table-tbody>tr>td,
:where(.css-mzwlov).ant-table-wrapper tfoot>tr>th,
:where(.css-mzwlov).ant-table-wrapper tfoot>tr>td {
    padding: 13px 16px;
}

// Table
.ant-table {
    border-radius: 10px !important;
}

:where(.css-mzwlov).ant-pagination .ant-pagination-item-active a {
    color: var(--primary-color) !important;
}

:where(.css-mzwlov).ant-pagination .ant-pagination-item-active {
    border-color: var(--primary-color) !important;
}

:where(.css-mzwlov).ant-switch.ant-switch-checked {
    background: var(--primary-color) !important;
}



.pt-5 {
    padding-top: 5px;
}

.icon-logement-select {
    padding: 0 0;
    vertical-align: top;
    width: 15px;
    height: 18px;

    img {
        width: 100%;
    }
}

.icon-agrandir-select {
    padding-top: 7px;
    vertical-align: top;
    width: 15px;
    height: 18px;

    img {
        width: 100%;
    }
}

.ant-pagination .ant-pagination-item-active a {
    color: var(--primary-color) !important;
}

.ant-pagination .ant-pagination-item-active {
    border-color: var(--primary-color) !important;
}

.infoContentLogement {
    color: white !important;

    .ant-popover-arrow {
        top: 1px !important;
    }

    .ant-popover-inner {
        background-color: var(--text-gray-color);
        color: white !important;
        border-radius: 25px;
        padding: 1px 8px;

        .ant-popover-inner-content {
            color: white !important;

            .infoLogement {
                p {
                    color: white !important;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }

    .ant-popover-arrow::before,
    .ant-popover-arrow::after {
        background-color: var(--text-gray-color);
    }
}

.infoContentLinge {
    color: white !important;

    .ant-popover-arrow {
        //  top: 1px !important;
    }

    .ant-popover-inner {
        background-color: var(--text-gray-color);
        color: white !important;
        border-radius: 25px;
        padding: 1px 8px;

        .ant-popover-inner-content {
            color: white !important;

            .infoLinge {
                p {
                    color: white !important;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }

    .ant-popover-arrow::before,
    .ant-popover-arrow::after {
        background-color: var(--text-gray-color);
    }
}

.infoContentEquipe {
    color: white !important;

    .ant-popover-arrow {
        bottom: 1px !important;
    }

    .ant-popover-inner {
        background-color: var(--text-gray-color);
        color: white !important;
        border-radius: 15px;
        padding: 1px 8px;

        .ant-popover-inner-content {
            color: white !important;

            .infoEquipe {
                p {
                    color: white !important;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }

    .ant-popover-arrow::before,
    .ant-popover-arrow::after {
        background-color: var(--text-gray-color);
    }
}

.ant-picker-cell-selected {
    .ant-picker-cell-inner {
        background-color: var(--primary-color) !important;
    }
}

.ant-picker-cell-inner::before {
    border-color: var(--primary-color) !important;
}

.color-5B6E8C {
    color: #5B6E8C !important;
}

.color-213856 {
    color: #213856 !important;
}

.color-A7A7A7 {
    color: #A7A7A7 !important;
}

.color-9AA1AB {
    color: #9AA1AB !important;
}

.color-ADB2BB {
    color: #ADB2BB !important;
}

.color-566F8F {
    color: #566F8F !important;
}

.color-7F7F7F {
    color: #7F7F7F !important;
}

.color-595959 {
    color: #595959 !important;
}

.display-none {
    display: none !important;
}

.sans-pro-light {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 300 !important;
}

.calibri-light {
    font-family: 'Calibri' !important;
    font-weight: 300 !important;
}

.sans-pro-semi-bold {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 600 !important;
}

.sans-pro-bold {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 900 !important;
}

.sans-pro-regular {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 400 !important;
}

.ant-checkbox-inner:after {
    background-color: var(--primary-color) !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
}

.ant-select-selection-item {
    .element-filter-tab {
        input {
            display: none;
        }

        .icone {
            display: none;
        }
    }
}

.status-classe-select {
    top: 225px !important;
    left: 468px !important;
    width: 200px !important;

    .element-filter-tab {
        .icone {
            display: inline-block;
            vertical-align: top;
            width: 13px;
            height: 13px;
            margin-left: 6px;
            margin-right: 6px;
            margin-top: 1px;

            img {
                width: 100%;
            }
        }

        .text {
            display: inline-block;
            vertical-align: top;
        }
    }
}

.status-classe-select-login {
    top: 270px !important;
    left: 305px !important;
    width: 200px !important;

    .element-filter-tab {
        .icone {
            display: inline-block;
            vertical-align: top;
            width: 13px;
            height: 13px;
            margin-left: 6px;
            margin-right: 6px;
            margin-top: 1px;

            img {
                width: 100%;
            }
        }

        .text {
            display: inline-block;
            vertical-align: top;
        }
    }
}

.status-classe-select-prestation {
    top: 275px !important;
    left: 390px !important;
    width: 200px !important;

    .element-filter-tab {
        .icone {
            display: inline-block;
            vertical-align: top;
            width: 13px;
            height: 13px;
            margin-left: 6px;
            margin-right: 6px;
            margin-top: 1px;

            img {
                width: 100%;
            }
        }

        .text {
            display: inline-block;
            vertical-align: top;
        }
    }
}

//bagde custom
.badge-green {
    padding: 2px 15px;
    border: 1px solid limegreen;
    color: limegreen;
    border-radius: 5px;
    display: inline-block;
    background-color: rgb(50, 205, 50, 0.1);
}

.badge-red {
    padding: 2px 15px;
    border: 1px solid red;
    color: red;
    border-radius: 5px;
    display: inline-block;
    background-color: rgb(255, 0, 0, 0.1);
}

.badge-blue {
    padding: 2px 15px;
    border: 1px solid blue;
    color: blue;
    border-radius: 5px;
    display: inline-block;
    background-color: rgb(0, 0, 255, 0.1);
}

.badge-grey {
    padding: 2px 15px;
    border: 1px solid grey;
    color: grey;
    border-radius: 5px;
    display: inline-block;
    background-color: rgb(241, 240, 240, 1);
}

//bagde custom contenu couleur plein
.badge-green-plein {
    padding: 2px 0;
    border: 1px solid limegreen;
    color: white;
    width: 75px;
    text-align: center;
    border-radius: 15px;
    display: inline-block;
    background-color: limegreen;
}

.badge-orange-plein {
    padding: 2px 0;
    border: 1px solid rgb(255, 181, 44, 1);
    color: black;
    width: 75px;
    text-align: center;
    border-radius: 15px;
    display: inline-block;
    background-color: rgb(255, 181, 44, 1);
}

.badge-black-plein {
    padding: 2px 0;
    border: 1px solid black;
    color: white;
    width: 75px;
    text-align: center;
    border-radius: 15px;
    display: inline-block;
    background-color: black;
}

.badge-red-plein {
    padding: 2px 0;
    border: 1px solid red;
    color: white;
    width: 75px;
    text-align: center;
    border-radius: 15px;
    display: inline-block;
    background-color: red;
}

.badge-violet-plein {
    padding: 2px 0;
    border: 1px solid blueviolet;
    color: white;
    width: 75px;
    text-align: center;
    border-radius: 15px;
    display: inline-block;
    background-color: blueviolet;
}

.badge-yellow-plein {
    padding: 2px 0;
    border: 1px solid lightcoral;
    color: white;
    width: 75px;
    text-align: center;
    border-radius: 15px;
    display: inline-block;
    background-color: lightcoral;
}


.SuccessNotificationClass {
    padding: 0 0 !important;
    height: 55px;
    width: 350px !important;
    border-radius: 0 !important;

    .iconeNotification {
        background-color: limegreen;
        color: white;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        padding-top: 10px;
        width: 50px;
        height: 40px;
    }

    .messageLineNotif {
        margin-top: 12px;
        margin-left: -50px;
        height: 6px !important;
        background-color: var(--disabled) !important;
        width: 372px !important;
        position: relative;
    }

    .messageLineNotifCovered {
        position: absolute;
        top: 0;
        left: 0;
        height: 6px !important;
        width: 0;
        background-color: var(--primary-color);
        animation: messageLineNotifAnimation 5s infinite;
    }

    .messageNotif {
        padding-left: 30px;
        padding-top: 15px;
        font-size: 15px;
        font-weight: 600;
    }

    .closeIconLineNotif {
        color: var(--primary-color);
        font-weight: 600;
        font-size: 17px;
    }

    @keyframes messageLineNotifAnimation {
        0% {
            width: 0;
        }

        100% {
            width: 100%;
        }
    }
}

.ant-notification-notice-wrapper {
    border-radius: 0 !important;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected>.ant-table-cell {
    background-color: var(--tertiary-color) !important;
}

.radiusTextArea {
    border-radius: 30px !important;
    padding: 20px;
}

.backWhite {
    background-color: white !important;
}

.colorBlack {
    color: black !important;
}


.relative {
    position: relative !important;
}

.pagination-custom {
    text-align: right;
    margin-top: 50px;

    ul {
        li {
            display: inline-block;
            width: 32px;
            height: 32px;
            border: 1px solid var(--disabled);
            text-align: center;
            font-size: 15px;
            margin-left: 10px;
            cursor: pointer;
            padding-top: 4px;
            border-radius: 5px;
        }

        li.active {
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
            font-weight: 600;
            cursor: default;
        }

        li.point {
            vertical-align: top;
            padding-top: 13px !important;
            border: 0 solid var(--primary-color);
            cursor: default;
        }

        li.fleche {
            vertical-align: top;
            border: 0 solid var(--primary-color);
            padding-top: 5px;
            color: #7F7F7F;
            font-size: 25px;
        }

        li.fleche.desactive {
            color: var(--disabled-light);
        }
    }
}

.disable-Button {
    background-color: rgba(128, 128, 128, 0.3) !important;
    box-shadow: 0 0 0 var(--disabled-light) !important;
}

.instructionApercu{
    height: 150px;
    border-radius: 10px;
}

.tagLinge{
    display: inline-block;
    margin-left: 10px;
    padding: 5px 10px;
    background-color: rgb(222, 238, 218);
    border-radius: 5px;
    color: rgb(42, 176, 80);
}

.tagLingeRed{
    display: inline-block;
    margin-left: 10px;
    padding: 5px 10px;
    background-color: rgb(252, 210, 210);
    border-radius: 5px;
    color: red;
}


.scroll-none{
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
}

.scroll-none::-webkit-scrollbar {
    display: none !important;
}

.bold{
    font-weight: bold !important;
}