.notes-page {
  margin: 0px 18px;

  .page-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .dateChange{
      margin-top: 20px;
      float: right;
      .libelle{
        font-weight: 600;
        margin-right: 15px;
        display: inline-block;
        vertical-align: top;
      }
      .icone{
        display: inline-block;
        padding-top: 0;
        margin-right: 15px;
      }
      .dateSelected{
        cursor: pointer;
        vertical-align: top;
        display: inline-block;
        font-size: 13px;
        margin-top: 1px;
        .icon-logement-select {
          padding: 0 0;
          vertical-align: top;
          width: 10px;
          margin-top: 2px;
          height: 17px;
          display: inline-block;
          img {
            width: 100%;
          }
        }
      }
    }
    h1 {
      font-size: 22px;
      font-weight: 600;
    }
  }

  .content-note{
    background-color: white;
    padding: 30px 30px;
    border-radius: 20px;
    .comment{
      cursor: pointer;
      border-radius: 20px;
      border: 1px solid rgba(0,0,0,0.1);
      padding: 15px 15px;
      margin-bottom: 20px;
      .identity{
        .image{
          display: inline-block;
          vertical-align: top;
          margin-right: 10px;
        }
        .infos{
          display: inline-block;
          .name{
            font-weight: 600;
          }
          .logement{
            font-weight: 500;
            color: rgba(0,0,0,0.4);
          }
        }
      }
      .pointtocorrect{
        margin-top: 20px;
        margin-left: 50px;
        .title{
          font-size: 13px;
          margin-bottom: 10px;
        }
        .element{
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          border: 2px solid var(--primary-color);
          color: var(--primary-color);
          padding: 5px 10px;
          border-radius: 50px;
          font-weight: 600;
          font-size: 10px;
        }
      }
      .start-note{
        text-align: right;
        margin-bottom: 40px;
      }
      .commentary{
        .title{
          font-size: 13px;
          margin-bottom: 5px;
        }
        .content-commentary{
          padding: 15px;
          font-size: 10px;
          background-color: rgba(0,0,0,0.1);
          border-radius: 10px;
        }
      }
    }
    .active-comment{
      border: 2px solid rgba(0,0,0,0.8) !important;
    }
    .line{
      height: 500px;
      width: 1px;
      margin-left: 30px;
      background-color: rgba(0,0,0,0.1);
    }
    .action{
      padding: 10px 15px;
      .message{
        font-size: 12px;
        font-weight: 600;
        color: rgba(0,0,0,0.3);
      }
      .action-option{
        .title{
          font-weight: 700;
          margin-bottom: 5px;
        }
        .subtitle{
          color: rgba(0,0,0,0.3);
          font-weight: 500;
          margin-bottom: 50px;
        }
        .list-action{
          .element-action{
            cursor: pointer;
            margin-bottom: 30px;
            display: flex;
            .image{
              vert-align: top;
              margin-top: 10px;
              display: inline-block;
              margin-right: 15px;
            }
            .content-action{
              display: inline-block;
              .title-action{
                font-weight: 600;
                margin-bottom: 5px;
              }
              .subtitle-action{
                font-size: 13px;
                color: rgba(0,0,0,0.3);
              }
            }
          }
        }
      }
    }
  }
}